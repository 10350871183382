import { useEffect, useState } from "react";
import { Container, Button, Toast, TypoGraph, Toast as toast } from "components";
import { ObjectStrings } from "interfaces";
import strings from "strings";
import { passwordStrengthValidator } from 'tools/validators'
import { InputFormField } from "components/hookForm";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { changePassword } from "api";
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { reportException } from "tools/logs";

interface IResetProps {
  styles: ObjectStrings;
  setStep: any;
}

interface IChangePassword {
  id?:string
  newPassword: string
  confirmPassword: string
}

const ResetPassword = (props: IResetProps) => {
  const { styles } = props;
  const [loading, setLoading] = useState(false)
  const methods = useForm<IChangePassword>()
  const { handleSubmit, getValues } = methods
  const [urlParams, setUrlParams] = useState<URLSearchParams>(new URLSearchParams(''))
  const [openInformationPrompt, setOpenInformationPrompt] = useState<boolean>(false)
  const search = useLocation().search
  const navigate = useNavigate()

  const handleDialogClose = () => {
      setOpenInformationPrompt(false)
      props.setStep(1)
      navigate(strings.ROUTES.LOGIN)
  }

  useEffect(() => {
    if (search.length > 0) {
      setLoading(true);
      setUrlParams(new URLSearchParams(search))
    }
    else {
      console.log('No params found');
    }
  }, [search])

  useEffect(() => {
    if (urlParams) {
      setLoading(false)
    }
  }, [urlParams])

  const onSubmit = (formData: IChangePassword) => {
    setLoading(true)
    const payLoad = {
      token: urlParams.get('token'),
      newPassword: formData.newPassword
    }

    changePassword(payLoad)
      .then((res: any) => {
        toast({ id:"passwordToast", title: 'Password resetted successfully!', subTitle: 'Your new password has been changed.' })
        props.setStep(0)
        navigate(strings.ROUTES.LOGIN)
      })
      .catch((error: any) => {
        reportException(error)
        if (error.response?.status == 401) {
          setOpenInformationPrompt(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container className={styles.formContainer}>
      {(urlParams.get('token') &&
      <>
        <FormProvider {...methods}>
          <InputFormField
              id={"newPassword"}
            name="newPassword"
            label={strings.NEW_PASSWORD}
            showPasswordIcon
            required
            validate={passwordStrengthValidator}
          />
          <InputFormField
              id={"confirmPassword"}
            name="confirmPassword"
            label={strings.CONFIRM_PASSWORD}
            showPasswordIcon
            required
            validate={(value: any) =>
              value === getValues('newPassword') || 'Passwords must match.'
            }
          />
          <Container className={styles.btnContainer}>
            <Button
                id={"changePassword"}
              loading={loading}
              label={strings.PROFILE_STRING.CHANGE_PASSWORD}
              onClick={handleSubmit(onSubmit)}
            />
          </Container>
        </FormProvider>
        <Dialog
            open={openInformationPrompt}
            keepMounted
            onClose={handleDialogClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>
              <Alert variant="filled" severity="warning">
                Your Token Expired
              </Alert>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Your password reset token has expired. Please request a new one from the next screen.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} label="OK" />
            </DialogActions>
        </Dialog>
      </>
      ) || (
        <>
          <TypoGraph
            id={'passwordRecoveryLinkError'}
            variant="body1"
            content="An error ocurred with your password recovery link. Please try again, or contact support for assistance."
            align="center"
            color="red"
          />
        </>
      )}
    </Container>
  );
};

export default ResetPassword;
