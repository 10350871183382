import React, { useEffect, useState } from "react";
import Isvg from "react-inlinesvg";
import * as Icons from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import styles from "./icon.module.scss";

export interface IAppProps {
  keyName?: string;
  name?: string;
  type?: "icons" | "images";
  boxClassName?: string;
  iconClassName?: string | object | undefined;
  hover?: boolean;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  color?: string;
  size?: number | string;
  height?: number;
  width?: number;
  alt?: string;
  children?: React.ReactNode;
  iconButton?: boolean;
  reference?: any;
  imageId?: string;
  url?: string;
  rotate?: string;
  materialIcon?: string;
  opacity?: number;
}

export const Icon: React.FunctionComponent<IAppProps> = (props) => {
  const {
    keyName = "",
    title,
    name,
    url,
    boxClassName,
    iconClassName,
    hover,
    height,
    width,
    size = 20,
    color,
    iconButton,
    type = "icons",
    onClick = () => {},
    alt = "vector_images",
    children,
    reference,
    imageId,
    rotate,
    materialIcon,
    opacity = 1,
  } = props;

  const node = (isIconButton?: boolean) => {
    try {
      if (materialIcon) {
        return MaterialIcon;
      }
      return type === "icons" ? (
        <span
          key={keyName}
          title={title}
          className={`${styles.iconContainer} ${hover ? styles.pointer : ""}
              ${boxClassName}`}
          onClick={(!isIconButton && onClick) || undefined}
        >
          <Isvg
            className={`${iconClassName} ${styles.iconSvg}`}
            src={require(`../../assets/${type}/${name}.svg`)}
            width={width || size}
            height={height || size}
            preProcessor={(code) =>
              code.replace(color ? /fill=".*?"/g : "", `fill='${color}'`)
            }
          />
        </span>
      ) : (
        <div className={boxClassName} onClick={onClick}>
          <img
            id={imageId}
            width={width || size}
            height={height || size}
            src={url ? url : require(`../../assets/${type}/${name}.png`)}
            alt={alt}
            ref={reference}
            style={{ transform: rotate }}
          />
        </div>
      );
    } catch(e: any) {
      const err = `Icon Error: ${
        !name ? "name is missing in prop" : e.message
      }`;
      return <span title={err}>icon error</span>;
    }
  };

  const isIconButton = Boolean(children || iconButton);
  const [MaterialIcon, setMaterialIcon] = useState<any>(null);

  useEffect(() => {
    if (materialIcon) {
      const IconComponent = Icons[materialIcon as keyof typeof Icons];
      if (IconComponent) {
        setMaterialIcon(<IconComponent className={boxClassName} style={{color: color, width: size, height: size, opacity}}/>);
      } else {
        console.warn(`Icon "${materialIcon}" is not exported from "@mui/icons-material".`);
      }
    }
  }, [materialIcon]);

  return (
    <>
      {isIconButton ? (
        <IconButton
          id={imageId}
          onClick={onClick}
          className={boxClassName}
          key={keyName}
        >
          {children ? children : MaterialIcon ? MaterialIcon : node(isIconButton)}
        </IconButton>
      ) : (
        node()
      )}
    </>
  );
};

export default Icon;