import React, { useEffect, useState } from 'react';
import { Container, HeaderBar } from 'components';
import strings from 'strings';
import { getMyCarrierList } from 'api/carriers';
import CarrierSelect from './carrierSelect';
import { Alert, Box, Collapse } from "@mui/material";
import ClaimsVehiclesTable from './claimsVehiclesTable';
import ClaimsFilters from './claimsFilters';
import ClaimsMap from './claimsMap';
import { getCarrierSafetyEventsTypes, getCarrierVehicles, getVehiclesSafetyEventsList, getRoutesList } from 'api/claimsSearching';
import LoadingWrapper from 'components/loadingWrapper';
import { APIProvider } from '@vis.gl/react-google-maps';
import { VehicleEvents } from 'interfaces/IClaimsSearching';
import { formatEventTypeLabel } from 'tools/helper';


interface IClaimsSearchingProps { }

const t = strings.MENU;
const cs = strings.CLAIMS_SEARCHING;

const ClaimsSearching: React.FC<IClaimsSearchingProps> = () => {
  const [loading, setLoading] = useState(true)
  const [vehicleSafetyLoading, setVehicleSafetyLoading] = useState<boolean>(false)
  const [routesLoading, setRoutesLoading] = useState<boolean>(false)
  const [filtersLoading, setFiltersLoading] = useState(false)

  const [carrierList, setCarrierList] = useState<any[]>([])
  const [safetyEventTypesList, setSafetyEventTypesList] = useState<any[]>([])
  const [hasSafetyEvents, setHasSafetyEvents] = useState<boolean>(false)
  const [vehicleList, setVehicleList] = useState<any[]>([])
  const [isCarrierSelected, setIsCarrierSelected] = useState<boolean>(false)
  const [selectedCarrierId, setSelectedCarrierId] = useState<number | null>(null)
  const [selectedEventId, setSelectedEventId] = useState<number | null>(null)
  const [selectedCarrierName, setSelectedCarrierName] = useState<string>('')

  const [vehiclesSafetyEventsList, setVehiclesSafetyEventsList] = useState<VehicleEvents | null>(null)
  const [routesList, setRoutesList] = useState<any[]>([])

  const [openAlert, setOpenAlert] = useState<boolean>(false)

  useEffect(() => {
    callCarriersList()
  }, [])

  useEffect(() => {
    if (vehiclesSafetyEventsList && vehiclesSafetyEventsList.totalEvents > cs.SAFETY_EVENTS_LIMIT) {
      setOpenAlert(true)
    } else {
      setOpenAlert(false)
    }
  }, [vehiclesSafetyEventsList])

  const callCarriersList = () => {
    getMyCarrierList()
      .then((res: any) => {
        const carrierInfo = res?.map(mapCarrierInfo)
        setCarrierList(carrierInfo)
      })
      .finally(() => setLoading(false))
  }

  const callSafetyEventsTypesList = (carrierId: number) => {
    getCarrierSafetyEventsTypes(carrierId)
      .then((res: any) => {
        if (res?.listSELabel) {
          const formattedList = res.listSELabel.map(
            (item: { id: number; eventType: string }) => ({
              id: item?.id,
              eventType: formatEventTypeLabel(item?.eventType),
              type: item?.eventType,
            })
          )
          setSafetyEventTypesList(formattedList)
        }
        if (res?.hasSafetyEvents) {
          setHasSafetyEvents(res.hasSafetyEvents)
        }
      })
      .finally(() => setFiltersLoading(false))
  }

  const callVehicleList = (carrierId: number) => {
    getCarrierVehicles(carrierId)
      .then((res: any) => {
        setVehicleList(res)
      })
  }

  const callVehiclesSafetyEventsList = (carrierId: number, payload: any) => {
    getVehiclesSafetyEventsList(carrierId, payload)
      .then((res: any) => {
        setVehiclesSafetyEventsList(res)
      }).finally(() => { setVehicleSafetyLoading(false) })
  }

  const callRoutesList = (payload: any) => {
    getRoutesList(payload)
      .then((res: any) => {
        setRoutesList(res)
      }).finally(() => { setRoutesLoading(false) })
  }

  const handleCarrierSelect = (carrier: any) => {
    const { carrierId, carrierName } = carrier
    setFiltersLoading(true)
    setHasSafetyEvents(false)
    setIsCarrierSelected(!!carrier)
    setSelectedCarrierName(carrierName)
    callSafetyEventsTypesList(carrierId)
    callVehicleList(carrierId)
    setSelectedCarrierId(carrierId)
    setVehiclesSafetyEventsList(null)
    setRoutesList([])    
  }

  const handleFiltersLoad = (loading: boolean) => {
    setFiltersLoading(loading)
  }

  const handleVehicleSafetyListCall = (payload: any) => {
    if (selectedCarrierId) {
      setVehicleSafetyLoading(true)
      callVehiclesSafetyEventsList(selectedCarrierId, payload)
    }
  }

  const handleRoutesListCall = (payload: any) => {
    if (selectedCarrierId) {
      setRoutesLoading(true)
      callRoutesList(payload)
    }
  }

  const handleSafetyEventSelect = (eventId: number | null) => {
    if (eventId) {
      setSelectedEventId(selectedEventId === eventId ? null : eventId)
    }
    else {
      setSelectedEventId(null)
    }
  }

  const resetSafetyAndRoutesList = () => {
    setVehiclesSafetyEventsList(null)
    setRoutesList([])
  }

  const getCarrierCode = (carrierCodes: any[]) => {
    const codePriorityOrder = ['POLICY_ID', 'DOT', 'MC', 'SCAC', 'UUID']
    const excludedCodeTypes = ['TCID']

    const carrierCode = carrierCodes.find((code: any) => {
      if (excludedCodeTypes.includes(code?.codeType)) {
        return false;
      }

      return codePriorityOrder.includes(code?.codeType) || true
    })

    return carrierCode ? carrierCode?.codeValue : ''
  }

  const mapCarrierInfo = (item: any) => {
    const carrierName = item?.carrierInfo?.carrierName || "Unknown"
    const carrierCodes = item?.carrierInfo?.carrierCodes || []
    const carrierCode = getCarrierCode(carrierCodes)
    const carrierId = item?.carrierInfo?.carrierId
    const formattedCarrierName = carrierCode ? `${carrierName} (${carrierCode})` : carrierName

    return {
      carrierId,
      carrierName: formattedCarrierName,
    }
  }

  return (
    <Container id="containterClaimsSearching">
      <HeaderBar title={t.CLAIMS_SEARCHING} />
      <Container sx={{ marginBottom: '1rem' }}>
        <LoadingWrapper
          isLoading={
            loading || vehicleSafetyLoading || routesLoading || filtersLoading
          }
        >
          <CarrierSelect
            carrierList={carrierList}
            onCarrierSelect={handleCarrierSelect}
          />
        </LoadingWrapper>
      </Container>
      {isCarrierSelected && (
        <Container>
          <LoadingWrapper isLoading={filtersLoading}>
            <ClaimsFilters
              carrierSelected={isCarrierSelected}
              safetyEventsList={safetyEventTypesList}
              hasSafetyEvents={hasSafetyEvents}
              vehicleList={vehicleList}
              searchBtnLoading={vehicleSafetyLoading || routesLoading}
              onFiltersLoad={handleFiltersLoad}
              onSafetySearch={handleVehicleSafetyListCall}
              onRoutesSearch={handleRoutesListCall}
              resetSafetyAndRoutesList={resetSafetyAndRoutesList}
            />
          </LoadingWrapper>
          <LoadingWrapper
            isLoading={filtersLoading || vehicleSafetyLoading || routesLoading}
          >
            <Collapse in={openAlert}>
              <Alert
                severity="warning"
                onClose={() => setOpenAlert(false)}
                sx={{ marginBottom: '1rem' }}
              >
                {cs.SAFETY_EVENTS_ALERT_CONTENT(cs.SAFETY_EVENTS_LIMIT)}
              </Alert>
            </Collapse>
            <Box
              sx={{
                bgcolor: '#cfe8fc',
                height: '40vh',
                padding: '10px',
                borderRadius: '4px',
                marginBottom: '1rem',
              }}
            >
              <APIProvider
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
              >
                <ClaimsMap
                  routesList={routesList}
                  safetyEventsList={vehiclesSafetyEventsList}
                  selectedEventId={selectedEventId}
                  onEventSelect={setSelectedEventId}
                />
              </APIProvider>
            </Box>
          </LoadingWrapper>
          <LoadingWrapper isLoading={filtersLoading || vehicleSafetyLoading}>
            <ClaimsVehiclesTable
              carrierSelected={isCarrierSelected}
              selectedCarrierId={selectedCarrierId}
              selectedCarrierName={selectedCarrierName}
              vehiclesSafetyEventsList={vehiclesSafetyEventsList}
              selectedEventId={selectedEventId}
              onEventSelect={handleSafetyEventSelect}
            />
          </LoadingWrapper>
        </Container>
      )}
    </Container>
  )
}

export default ClaimsSearching;
