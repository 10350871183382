import request from 'tools/request'

export const getCarrierSafetyEventsTypes = async (carrierId: number) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/${carrierId}/safety-events/types`,
  )
  return res?.data
}

export const getCarrierVehicles = async (carrierId: number) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/${carrierId}/vehicles`,
  )
  return res?.data
}

export const getRoutesList = async (payLoad: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL}routes`,
    payLoad,
  )
  return res?.data
}

export const getVehiclesSafetyEventsList = async (carrierId: number, payload: any) => {
  const res = await request.post(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/${carrierId}/safety-events`,
    payload
  )
  return res?.data
}

export const getSafetyEventDetail = async (carrierId: number, safetyId: any) => {
  const res = await request.get(
    `${process.env.REACT_APP_CARRIER_API_URL}carriers/${carrierId}/safety-events/${safetyId}`,
  )
  return res?.data
}