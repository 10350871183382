import { Icon } from "components";

const legendItems: any = {
    0: [
      {
        icon: <Icon materialIcon="CarCrashTwoTone" color="#FF0000" />,
        label: 'Crash',
      },
      {
        icon: <Icon materialIcon="WarningAmberTwoTone" color="#FF0000" />,
        label: 'Critical Tailgating',
      },
      {
        icon: <Icon materialIcon="ArrowUpwardTwoTone" color="#FF0000" />,
        label: 'Harsh Acceleration',
      },
      {
        icon: <Icon materialIcon="SpeedTwoTone" color="#FF0000" />,
        label: 'Harsh Braking',
      },
      {
        icon: <Icon materialIcon="TurnLeftTwoTone" color="#FF0000" />,
        label: 'Harsh Corner',
      },
      {
        icon: <Icon materialIcon="DirectionsCarTwoTone" color="#FF0000" />,
        label: 'Lane Departure',
      },
      {
        icon: <Icon materialIcon="DirectionsCarTwoTone" color="#FF0000" />,
        label: 'Near Collision',
      },
      {
        icon: <Icon materialIcon="ReportProblemTwoTone" color="#FF0000" />,
        label: 'Other',
      },
      {
        icon: <Icon materialIcon="WrongLocationTwoTone" color="#FF0000" />,
        label: 'Parked On Highway Ramp',
      },
      {
        icon: <Icon materialIcon="CarCrashTwoTone" color="#FF0000" />,
        label: 'Possible Accident',
      },
      {
        icon: <Icon materialIcon="RemoveRoadTwoTone" color="#FF0000" />,
        label: 'Rough Uneven Surface',
      },
      {
        icon: <Icon materialIcon="SyncProblemTwoTone" color="#FF0000" />,
        label: 'Roll Stability',
      },
      {
        icon: <Icon materialIcon="TrafficTwoTone" color="#FF0000" />,
        label: 'Sign Violation Or Rolling',
      },
      {
        icon: <Icon materialIcon="WarningAmberTwoTone" color="#FF0000" />,
        label: 'Tailgating',
      },
    ],
    1: [
      {
        icon: <Icon materialIcon="LocationOnTwoTone" color="#007BFF" />,
        label: 'Route Start',
      },
      {
        icon: <Icon materialIcon="LocationOnTwoTone" color="#6A0DAD" />,
        label: 'Route End',
      },
      {
        icon: <Icon materialIcon="HorizontalRule" color="#0096FF" />,
        label: 'Normal Speed',
      },
      {
        icon: <Icon materialIcon="HorizontalRule" color="#FF8000" />,
        label: 'Speeding',
      },
      {
        icon: <Icon materialIcon="HorizontalRule" color="#FF0000" />,
        label: 'Speeding (10+ mph)',
      },
      {
        icon: <Icon materialIcon="HorizontalRule" color="#A200ED" />,
        label: 'Speeding (15+ mph)',
      },
      {
        icon: <Icon materialIcon="HorizontalRule" color="#1F001F" />,
        label: 'Speeding (20+ mph)',
      },
    ],
  }

  export default legendItems;