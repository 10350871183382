import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, TypoGraph, } from 'components';
import strings from 'strings';
import styles from './claimsFilters.module.scss'
import { Autocomplete, Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormHelperText, Grid, TextField } from '@mui/material';
import moment, { Moment } from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isArrayEmpty } from 'tools/helper';
import { SafetyEventType, VehicleDetails } from 'interfaces/IClaimsSearching';

interface IClaimsFiltersProps {
    carrierSelected: boolean;
    safetyEventsList: SafetyEventType[];
    hasSafetyEvents: boolean;
    vehicleList: VehicleDetails[];
    searchBtnLoading: boolean;
    onFiltersLoad: (loading: boolean) => void;
    onSafetySearch: (payload: any) => void;
    onRoutesSearch: (payload: any) => void;
    resetSafetyAndRoutesList: () => void;
}

const t = strings.CLAIMS_SEARCHING


const ClaimsFilters: React.FC<IClaimsFiltersProps> = ({ carrierSelected, safetyEventsList, hasSafetyEvents, vehicleList, searchBtnLoading, onFiltersLoad, onSafetySearch, onRoutesSearch, resetSafetyAndRoutesList }) => {
    const [loading, setLoading] = useState(true)
    const [selectedSafetyEventsTypes, setSelectedSafetyEventsTypes] = useState<any[]>([])
    const [selectedVehicles, setSelectedVehicles] = useState<any[]>([])
    const [startDate, setStartDate] = useState<Moment | null>(null)
    const [endDate, setEndDate] = useState<Moment | null>(null)
    const [endDateError, setEndDateError] = useState<boolean>(false)
    const [safetyEventsCheckbox, setSafetyEventsCheckbox] = useState<boolean>(false)
    const [routesCheckbox, setRoutesCheckbox] = useState<boolean>(false)
    const [searchBtnEnabled, setSearchBtnEnabled] = useState<boolean>(false)

    const isSafetyFirstLoad = useRef(false)

    const sortedSafetyEventsTypes = useMemo(() => {
        return [...safetyEventsList]
            .map((item) => {
                const firstLetter = item?.eventType[0].toUpperCase()
                return {
                    ...item,
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                }
            })
            .sort((a, b) => a.eventType?.localeCompare(b?.eventType))
    }, [safetyEventsList])

    const sortedVehicleList = useMemo(() => {
        return [...vehicleList]
            .map((item) => {
                const firstLetter = item?.vehicleId?.assetEldId[0].toUpperCase()
                return {
                    ...item,
                    firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                }
            })
            .sort((a, b) => a.vehicleId?.assetEldId.localeCompare(b?.vehicleId?.assetEldId))
    }, [vehicleList])

    useEffect(() => {
        if (carrierSelected &&
            Array.isArray(sortedVehicleList) &&
            sortedVehicleList.length > 0 &&
            Array.isArray(sortedSafetyEventsTypes)) {

            const now = moment();
            const oneWeekAgo = moment().subtract(7, 'days')
            setStartDate(oneWeekAgo)
            setEndDate(now)
            setEndDateError(false)
            setSelectedSafetyEventsTypes([])
            setSelectedVehicles([])
            setSafetyEventsCheckbox(hasSafetyEvents)
            setRoutesCheckbox(false)
            isSafetyFirstLoad.current = hasSafetyEvents
        }
    }, [carrierSelected, sortedSafetyEventsTypes, sortedVehicleList, hasSafetyEvents])

    useEffect(() => {
        const isEnabled = !!(startDate && endDate && !endDateError &&
            (
                (safetyEventsCheckbox && !routesCheckbox) ||
                (routesCheckbox && selectedVehicles.length > 0)
            )
        )
        setSearchBtnEnabled(isEnabled)

    }, [selectedVehicles, startDate, endDate, endDateError, safetyEventsCheckbox, routesCheckbox])

    useEffect(() => {
        if (carrierSelected) {
            if (hasSafetyEvents && startDate && endDate && searchBtnEnabled && isSafetyFirstLoad.current) {
                handleSearchVehicles()
                isSafetyFirstLoad.current = false
                onFiltersLoad(false)
            }
            onFiltersLoad(false)
        }
    }, [carrierSelected, startDate, endDate, hasSafetyEvents, searchBtnEnabled])

    const formatDates = (startDate: any, endDate: any) => ({
        startDate: startDate.format('YYYY-MM-DD[T]HH:mm:ss'),
        endDate: endDate.format('YYYY-MM-DD[T]HH:mm:ss')
    })

    const buildVehicleIds = (selectedVehicles: any[]) =>
        selectedVehicles.map(vehicle => ({
            companyEldAuthenticationId: vehicle?.vehicleId?.companyEldAuthenticationId,
            assetEldId: vehicle?.vehicleId.assetEldId
        }))

    const calculateDistributedPoints = (vehicleCount: number, maxPoints: number) => {
        if (vehicleCount === 0) return 0
        const maxPointsPerVehicle = Math.floor(maxPoints / vehicleCount)
        return maxPointsPerVehicle
    }

    const handleSearchVehicles = () => {
        if (!searchBtnEnabled) return;

        const { startDate: formattedStartDate, endDate: formattedEndDate } = formatDates(startDate, endDate)
        const vehicleIds = selectedVehicles.length > 0 ? buildVehicleIds(selectedVehicles) : []

        resetSafetyAndRoutesList()

        if (safetyEventsCheckbox) {
            const selectedEventTypes = selectedSafetyEventsTypes.map(event => event?.type)
            const payload = {
                vehicleIds,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                types: selectedEventTypes ?? []
            }
            onSafetySearch(payload)
        }

        if (routesCheckbox && selectedVehicles?.length > 0) {
            const maxPoints = 30000
            const distributedPoints = calculateDistributedPoints(selectedVehicles.length, maxPoints)
            const payload = {
                vehicleIds,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                distributedPoints: distributedPoints
            }
            onRoutesSearch(payload)
        }
    }

    const handleSafetyEventsChange = (event: any) => {
        if (hasSafetyEvents) {
            setSafetyEventsCheckbox(event?.target?.checked)
        }
    }

    const handleRoutesChange = (event: any) => {
        setRoutesCheckbox(event?.target?.checked)
    }

    const handleSafetyEventsFilter = (event: any, newValue: SafetyEventType[]) => {
        setSelectedSafetyEventsTypes(newValue)
    }

    const handleVehiclesFilter = (event: any, newValue: VehicleDetails[]) => {
        setSelectedVehicles(newValue);
    }

    const handleStartDateChange = (newValue: moment.Moment | null) => {
        setStartDate(newValue);
        if (endDate && newValue) {
            if (endDate?.isBefore(newValue) || endDate?.isSame(newValue)) {
                setEndDate(newValue.clone().add(1, 'minute'))
                setEndDateError(false)
            }
        }
    }

    const handleEndDateChange = (newValue: moment.Moment | null) => {
        if (newValue && startDate) {
            if (startDate && newValue.diff(startDate, 'minute') < 1) {
                setEndDateError(true)
                return
            }
            setEndDateError(false)
        }
        setEndDate(newValue)
    }

    return (
        <Card variant='outlined' sx={{ marginBottom: 2, padding: 2 }}>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={2} display={"flex"} alignItems="center" gap={2}>
                    <FormGroup row={true}>
                        <Tooltip
                            title={t.NO_SAFETY_TOOLTIP}
                            sx={{ background: '100%' }}
                            show={!hasSafetyEvents}>
                            <FormControlLabel control={<Checkbox disabled={!hasSafetyEvents} id='checkboxSafety'
                                checked={safetyEventsCheckbox} onChange={handleSafetyEventsChange} />} label={t.SAFETY_EVENTS} />
                        </Tooltip>
                        <FormControlLabel control={<Checkbox checked={routesCheckbox} onChange={handleRoutesChange} id='checkboxVehicles' />} label={t.ROUTES} />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} display={"flex"} alignItems="center" gap={2}>
                    <Tooltip
                        title={t.NO_SAFETY_TOOLTIP}
                        containerClassName={styles.tooltipDisabled}
                        sx={{ background: '100%' }}
                        show={!hasSafetyEvents}>
                        <Autocomplete
                            id="SafetyEventsTypesOptions"
                            disabled={!hasSafetyEvents}
                            multiple
                            className={`${!hasSafetyEvents ? styles.disabled : ''}`}
                            sx={{ width: '100%' }}
                            options={sortedSafetyEventsTypes}
                            groupBy={(option) => option?.firstLetter}
                            getOptionLabel={(option) => option?.eventType}
                            filterSelectedOptions
                            value={selectedSafetyEventsTypes}
                            onChange={handleSafetyEventsFilter}
                            renderInput={(params) => (
                                (!hasSafetyEvents ?
                                    <TextField
                                        {...params}
                                        label={t.NO_SAFETY_LABEL}
                                        placeholder={t.NO_SAFETY_LABEL}
                                    /> : <TextField
                                        {...params}
                                        label={t.SEARCH_SAFETY_LABEL}
                                        placeholder={t.SEARCH_SAFETY_LABEL}
                                    />)
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12} md={4} display={"flex"} alignItems="center" justifyContent={'space-between'} gap={2}>
                    <Grid>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={t.START_DATE_LABEL}
                                value={startDate}
                                onChange={handleStartDateChange}
                                maxDateTime={endDate ? endDate.clone().subtract(1, 'minute') : undefined}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <TypoGraph variant="h2" mb={0}>
                        {'-'}
                    </TypoGraph>
                    <Grid>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label={t.END_DATE_LABEL}
                                value={endDate}
                                onChange={handleEndDateChange}
                                minDateTime={startDate ? startDate.clone().add(1, 'minute') : undefined}
                                disabled={!startDate}
                            />
                        </LocalizationProvider>
                        {endDateError && <FormHelperText error>{t.END_DATE_ERROR}</FormHelperText>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={12} md={12} display={"flex"} alignItems="center" gap={2}>
                    <Autocomplete
                        multiple
                        id="vehiclesOptions"
                        sx={{ width: '100%' }}
                        options={sortedVehicleList}
                        groupBy={(option) => option?.firstLetter}
                        getOptionLabel={(option) => {
                            return option?.vin ? `${option?.vehicleId?.assetEldId} (${option?.vin})` : `${option?.vehicleId?.assetEldId} (-)`;
                        }}
                        filterSelectedOptions={true}
                        value={selectedVehicles}
                        onChange={handleVehiclesFilter}
                        renderOption={(props, option) => {
                            const optionKey = `${option?.vehicleId?.assetEldId}-${option?.vin}`
                            return (
                                <li {...props} key={optionKey}>
                                    {option?.vin ? `${option?.vehicleId?.assetEldId} (${option?.vin})` : `${option?.vehicleId?.assetEldId} (-)`}
                                </li>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t.SEARCH_VEHICLES}
                                placeholder={t.SEARCH_VEHICLES}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} display={"flex"} alignItems="center" justifyContent={"end"}>
                    <Button
                        sx={{ borderRadius: 25, marginRight: 3, minWidth: 120 }}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleSearchVehicles}
                        disabled={!searchBtnEnabled || searchBtnLoading}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {searchBtnLoading && <CircularProgress size={16} color="inherit" sx={{ marginRight: 1 }} />}
                            {t.SEARCH}
                        </Box>
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

export default ClaimsFilters;
