import React, { useState, useCallback, useRef, useEffect } from 'react'
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  InfoWindow,
  Map,
  ControlPosition,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps'
import { Icon } from 'components'
import { Polyline } from 'components/maps'
import { VehicleRoutes, VehicleEvents, VehicleEvent, Route } from 'interfaces/IClaimsSearching'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import type { Marker } from '@googlemaps/markerclusterer'
import { formatDate, formatEventTypeLabel, getEventIcon } from 'tools/helper'
import ClaimsMapsLegend from '../claimsMapLegend'

interface IClaimsMapsProps {
  routesList?: VehicleRoutes[]
  safetyEventsList?: VehicleEvents | null
  selectedEventId: number | null
  onEventSelect: any
}

export type AnchorPointName = keyof typeof AdvancedMarkerAnchorPoint

const ClaimsMap: React.FC<IClaimsMapsProps> = (props) => {
  const { routesList, safetyEventsList, selectedEventId, onEventSelect } = props
  const map = useMap()
  const maps = useMapsLibrary('maps')
  const [defaultZoom, setDefaultZoom] = useState(4)
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 39.8283,
    lng: -98.5795,
  })
  const [hoveredEventId, setHoveredEventId] = useState<string | null>(null)
  const [hoveredEvent, setHoveredEvent] = useState<VehicleEvent | null>(null)
  const [hoveredRouteId, setHoveredRouteId] = useState<string | null>(null)
  const [hoveredRoute, setHoveredRoute] = useState({
    vehicleId: null as string | null,
    route: null as Route | null,
  })
  const [infoWindowShown, setInfoWindowShown] = useState(false)
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({})
  const clusterer = useRef<MarkerClusterer | null>(null)

  useEffect(() => {
    if (!map) return
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map })
    }
  }, [map])

  useEffect(() => {
    clusterer.current?.clearMarkers()
    clusterer.current?.addMarkers(Object.values(markers))
  }, [markers])

  useEffect(() => {
    if ((routesList || safetyEventsList) && map) {
      const defaultCenter = { lat: 39.8283, lng: -98.5795 }
      map.setCenter(defaultCenter)
      map.setZoom(4)

      handleMarkerUnhover()
    }
  }, [routesList, safetyEventsList, map])

  useEffect(() => {
    if (selectedEventId && map) {
      const selectedEvent = safetyEventsList?.safetyEvents?.find(
        (event) => event.id === selectedEventId
      )
      if (selectedEvent) {
        map.panTo({
          lat: selectedEvent.latitude,
          lng: selectedEvent.longitude,
        })
        map.setZoom(14)
      }
    }
  }, [selectedEventId])

  const onMapClick = useCallback(() => {
    setHoveredEventId(null)
    setHoveredEvent(null)
    setHoveredRouteId(null)
    setHoveredRoute({
      vehicleId: null,
      route: null,
    })
    setInfoWindowShown(false)
  }, [])

  const handleEventMarkerHover = useCallback((event: VehicleEvent | null) => {
    if (event) {
      setHoveredEventId(event.id?.toString() || null)
      setHoveredEvent(event)
      setInfoWindowShown(true)
    }
  }, [])

  const handleRouteMarkerHover = useCallback(
    (vehicleId: string, route: Route) => {
      if (vehicleId && route) {
        setHoveredRouteId(route.id?.toString() || null)
        setHoveredRoute({ vehicleId, route })
        setInfoWindowShown(true)
      }
    },
    []
  )

  const handleMarkerUnhover = useCallback(() => {
    setInfoWindowShown(false)
    setHoveredEventId(null)
    setHoveredEvent(null)
    setHoveredRouteId(null)
    setHoveredRoute({ vehicleId: null, route: null })
  }, [])

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return
    if (!marker && !markers[key]) return

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker }
      } else {
        const newMarkers = { ...prev }
        delete newMarkers[key]
        return newMarkers
      }
    })
  }

  const handleSafetyEventClick = (safetyId: number) => {
    handleMarkerUnhover()
    onEventSelect(safetyId)
  }

  const getRouteColor = (speed: number, speedLimit: number) => {
    if (!speed || !speedLimit) {
      return '#0096FF'
    }
    const speedDifference = speed - speedLimit

    if (speedDifference <= 0) {
      return '#0096FF'
    } else if (speedDifference <= 10) {
      return '#FF8000'
    } else if (speedDifference <= 15) {
      return '#FF0000'
    } else if (speedDifference <= 20) {
      return '#A200ED'
    } else {
      return '#1F001F'
    }
  }

  return (
    <Map
      defaultZoom={defaultZoom}
      defaultCenter={defaultCenter}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      clickableIcons={false}
      onClick={onMapClick}
      mapId={'CLAIMS_MAP'}
    >
      <ClaimsMapsLegend position={ControlPosition.RIGHT_TOP} />
      {routesList &&
        routesList.map((vehicle: VehicleRoutes) =>
          vehicle.routes
            .filter((route: Route) => route.latitude && route.longitude)
            .map((route: Route, index: any) => {
              if (index === 0 || index === vehicle.routes.length - 1) {
                const isStart = index === 0
                return (
                  <AdvancedMarker
                    key={route?.id}
                    position={{ lat: route?.latitude, lng: route?.longitude }}
                    clickable={true}
                    anchorPoint={AdvancedMarkerAnchorPoint.BOTTOM}
                    style={{
                      transform: `scale(${
                        hoveredRouteId === route.id.toString() ? 1.3 : 1
                      })`,
                      transition: 'transform 0.2s ease-in-out',
                    }}
                    onMouseEnter={() =>
                      handleRouteMarkerHover(
                        vehicle.vehicleId.assetEldId,
                        route
                      )
                    }
                    onMouseLeave={handleMarkerUnhover}
                    
                  >
                      <Icon
                        name={isStart ? 'startRoute' : 'endRoute'}
                        size={50}
                        color={isStart ? '#007BFF' : '#6A0DAD'}
                        materialIcon="LocationOnTwoTone"
                      />
                  </AdvancedMarker>
                )
              }
            })
        )}

      {routesList &&
        routesList.map((vehicle: VehicleRoutes) =>
          vehicle.routes
            .filter((route: Route) => route.latitude && route.longitude)
            .map((route: Route, index: number) => {
              if (index === vehicle.routes?.length - 1) {
                return null
              }

              const startPoint = {
                lat: route?.latitude,
                lng: route?.longitude,
              }
              const endPoint = {
                lat: vehicle.routes[index + 1]?.latitude,
                lng: vehicle.routes[index + 1]?.longitude,
              }

              const strokeColor = getRouteColor(route?.speed, route?.speedLimit)

              return (
                <Polyline
                  key={`${route?.id}-${index}`}
                  path={[startPoint, endPoint]}
                  geodesic={true}
                  strokeColor={strokeColor}
                  strokeOpacity={0.75}
                  strokeWeight={4}
                />
              )
            })
        )}

      {infoWindowShown && hoveredRoute && (
        <InfoWindow
          disableAutoPan
          headerDisabled
          pixelOffset={[0, -40]}
          position={{
            lat: hoveredRoute.route?.latitude ?? 0,
            lng: hoveredRoute.route?.longitude ?? 0,
          }}
        >
          <h4>Asset ID: {hoveredRoute.vehicleId ?? '-'}</h4>
          <h4>
            Time:{' '}
            {hoveredRoute.route?.eldTime
              ? formatDate(hoveredRoute.route?.eldTime)
              : '-'}
          </h4>
          <h4>Speed: {hoveredRoute.route?.speed ?? '-'}</h4>
        </InfoWindow>
      )}

      {safetyEventsList && safetyEventsList.safetyEvents && 
        safetyEventsList.safetyEvents.length > 0 &&
        safetyEventsList.safetyEvents
          .filter(
            (safety: VehicleEvent) => safety?.latitude && safety?.longitude
          )
          .map((safety: VehicleEvent) => (
            <AdvancedMarker
              ref={(marker) =>
                setMarkerRef(marker, safety.id?.toString() || '')
              }
              key={safety?.id}
              position={{ lat: safety?.latitude, lng: safety?.longitude }}
              clickable={true}
              anchorPoint={AdvancedMarkerAnchorPoint.TOP}
              onClick={() => handleSafetyEventClick(safety.id)}
              style={{
                transform: `scale(${
                  hoveredEventId === safety.id.toString() ? 1.3 : 1
                })`,
                transition: 'transform 0.2s ease-in-out',
              }}
              onMouseEnter={() => handleEventMarkerHover(safety)}
              onMouseLeave={handleMarkerUnhover}
            >
                <Icon
                  name="safety"
                  size={40}
                  color="#FF0000"
                  materialIcon={getEventIcon(safety?.type)}
                />
            </AdvancedMarker>
          ))}

      {infoWindowShown && hoveredEvent && (
        <InfoWindow
          disableAutoPan
          headerDisabled
          position={{ lat: hoveredEvent.latitude, lng: hoveredEvent.longitude }}
        >
          <h4>Asset ID: {hoveredEvent?.vehicleId?.assetEldId ?? '-'}</h4>
          <h4>
            Event time:{' '}
            {hoveredEvent?.time ? formatDate(hoveredEvent.time) : '-'}
          </h4>
          <h4>Event type: {formatEventTypeLabel(hoveredEvent.type) ?? '-'}</h4>
        </InfoWindow>
      )}
    </Map>
  )
}

export default ClaimsMap
