import { IMenuItem } from "interfaces"
import { IMenuAccess } from "interfaces/IMenuAccess"
import { getInsuranceMenuAccess } from "rbac"
import strings from "strings"
import { MenuList } from "types/menuList"

const insuranceMenuBar = () => {
  const menuBarList: MenuList = {
    list1: [],
    list2: []
  }

  const menuList1: IMenuItem[] = [
    {
      name: strings.MENU.DASHBOARD,
      icon: 'icon_dashboard',
      id: 'dashboard',
    },
    {
      name: strings.MENU.CARRIERS,
      icon: 'icon_carrier',
      id: 'carriers',
    },
    {
      name: strings.MENU.REPORTING,
      muiIcon: 'AutoGraph',
      id: 'reporting',
    },
    {
      name: strings.MENU.CLAIMS_SEARCHING,
      muiIcon: 'Map',
      id: 'claims-searching',
    },
  ]
  
  const menuList2: IMenuItem[] = [
    {
      name: strings.MENU.HELP,
      icon: 'icon_help',
      id: 'help',
      url: 'https://truckercloud.zendesk.com/hc/en-us/requests/new?ticket_form_id=4415135008155',
    },
    {
      name: strings.MENU.SETTINGS,
      icon: 'icon_setting',
      id: 'settings',
    },
    {
      name: strings.MENU.PROFILE,
      icon: 'icon_profile',
      id: 'profile',
    },
  ]

  const insurerMenuAccess: IMenuAccess[] = getInsuranceMenuAccess()
  menuBarList.list1.push(...menuList1.filter((item: IMenuItem) => insurerMenuAccess.some(accessItem => accessItem.name === item.name)))
  menuBarList.list2.push(...menuList2.filter((item: IMenuItem) => insurerMenuAccess.some(accessItem => accessItem.name === item.name)))

  return menuBarList
}

export default insuranceMenuBar
