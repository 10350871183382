import { useEffect, useState } from 'react'
import { getAgentMyCarrierList, getMyCarrierList } from 'api'
import {
  Container,
  DataGridTable,
  Blade,
  Icon,
  TypoGraph,
  Chip,
  Toast as toast,
  SubmitDialog,
  Button,
  Tooltip,
} from 'components'
import { getCarrierTableData } from 'tools/tableDataHelper'
import { useNavigate } from 'react-router'
import styles from './myCarriers.module.scss'
import { isAgentLogin } from 'tools/auth'
import { FormProvider, useForm } from 'react-hook-form'
import strings from 'strings'
import { editCarrier } from 'api'
import { copyToClipboard, generateInvitationUrl, getCodeTypesAndCodeValues } from 'tools/helper'
import { InputFormField } from 'components/hookForm'
import { emailValidator } from 'tools/validators'
import { actionList, myCarrierList } from './myCarriersTableData'
import { useGridApiRef } from '@mui/x-data-grid'
import { useSelector } from 'react-redux'
import { changeCarrierStatus, inviteCarrier, offboardCarrier } from 'api/carriers'
import { Grid, InputAdornment, TextField } from '@mui/material'

const t = strings.CARRIERS
const st = strings.CARRIER_STATUS_CHANGE

interface IMyCarrierProps {}

interface IEditCarrierPayload {
  policyId?: string;
  dot?: string;
  carrierName?: string;
  cuid?: string;
}

const MyCarrier: React.FC<IMyCarrierProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [btnLoader, setBtnLoader] = useState(false)
  const [showTemplateForm, setShowTemplateForm] = useState<boolean>(false)
  const [isEditForm, setIsEditForm] = useState(false)
  const [showCarrierDetails, setShowCarrierDetails] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [myCarrierData, setMyCarrierData] = useState([])
  const [companyHasCUID, setcompanyHasCUID] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<any>(null)
  const [selectedCarrier, setSelectedCarrier] = useState<string>('')
  const [initialFormValues, setInitialFormValues] = useState<any>({})
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [changeStatusText, setChangeStatusText] = useState<any>(null)
  const navigate = useNavigate()
  const apiRef = useGridApiRef()

  const methods = useForm<any>({
    reValidateMode: 'onChange',
  })

  const companyProfileData = useSelector(
    (state: any) => state?.whiteLabel?.companyProfile
  )

  const { handleSubmit, reset } = methods

  const { myCarrierColumn } = myCarrierList

  const isInsuranceCompany = localStorage.getItem('isInsurance') ? (localStorage.getItem('isInsurance') === 'true') : false

  const statusChangeOptions = {
    pause: {
      title: st.PAUSE_TITLE,
      body: st.CONFIRMATION_PAUSE_MESSAGE,
    },
    resume: {
      title: st.RESUME_TITLE,
      body: st.CONFIRMATION_RESUME_MESSAGE,
    },
    offboard: {
      title: st.OFFBOARD_TITLE,
      body: st.CONFIRMATION_OFFBOARD_MESSAGE,
    },
    reconnect: {
      title: st.RECONNECT_TITLE,
      body: '',
    },
  }

  useEffect(() => {
    callCarriersList()
  }, [])

  useEffect(() => {
    if (companyProfileData) {
      setcompanyHasCUID(companyProfileData 
        && companyProfileData.carrierUniqueIds 
        && companyProfileData.carrierUniqueIds.length > 0)
    }
  }, [companyProfileData])

  const callCarriersList = () => {
    setLoading(true)
    if (isAgentLogin()) {
      getAgentMyCarrierList()
        .then((res: any) => {
          const data = getCarrierTableData(res.carrierList)
          setMyCarrierData(data)
        })
        .finally(() => setLoading(false))
    } else {
      getMyCarrierList()
        .then((res: any) => {
          const data = getCarrierTableData(res)
          setMyCarrierData(data)
        })
        .finally(() => setLoading(false))
    }
  }

  myCarrierColumn[1].handleClick = (row: any) => {
    if (isInsuranceCompany || isAgentLogin()) {
      if (row.dot !== "" && row.dot !== "-") {
        localStorage.setItem('carrierCodeType', 'DOT')
        localStorage.setItem('carrierCodeValue', row.dot)
      }
      else if (row.mc !== "" && row.mc !== "-") {
        localStorage.setItem('carrierCodeType', 'MC')
        localStorage.setItem('carrierCodeValue', row.mc)
      }
      else if (row.uuid !== "" && row.uuid !== "-") {
        localStorage.setItem('carrierCodeType', 'UUID')
        localStorage.setItem('carrierCodeValue', row.uuid)
      }
      else {
        localStorage.removeItem('carrierCodeType')
        localStorage.removeItem('carrierCodeValue')
      }
      localStorage.setItem('eldProviderRisk', row.eldVendorDisplayName)
      localStorage.setItem('carrierName', row.carrierName)
      localStorage.setItem('carrierId', row.carrierId)
      navigate('details')
    }
    else {
      setSelectedRow(row)
      setShowCarrierDetails(true)
    }
  }

  myCarrierColumn[4].handleClick = (row: any) => {
    copyToClipboard(row.email)
  }

  const handleActions = (_id: number | string, actionName: string) => {
    setSelectedCarrier(_id.toString())
    const templateFormData = myCarrierData.filter(
      (item: any) => {
        const splittedId = _id.toString().split('|')
        const id = splittedId[0]
        const eld = splittedId[1]
        return item.carrierId == id && item.eldVendorDisplayName == eld
      }
    )
    setSelectedRowData(templateFormData[0])

    const action = actionName?.toLowerCase();
    if (action === 'edit') {
      setIsEditForm(true)
      reset(templateFormData[0])
      setInitialFormValues(templateFormData[0])
      setShowTemplateForm(true)
    }

    if (action && action in statusChangeOptions) {
      const { title, body } = statusChangeOptions[action as keyof typeof statusChangeOptions]
      setOpenDialog(true)
      setChangeStatusText({
        type: action,
        title,
        body,
        confirmBtn: st.CONFIRM_BUTTON_TEXT,
      })
    }
  }

  const handleStatusChange = () => {
    if (!changeStatusText || !selectedRowData) return

    const { type } = changeStatusText
    const { carrierId, eldVendor } = selectedRowData

    const closeDialog = () => {
      setBtnLoader(false)
      setOpenDialog(false)
    };
    setBtnLoader(true)
    if (type === 'pause' || type === 'resume') {
      const payload = {
        status: type === 'pause' ? 'PAUSED' : 'ACTIVE',
        codeType: 'TCID',
        codeValue: carrierId,
        eldProviderName: eldVendor,
      }

      changeCarrierStatus(payload)
        .then((res: any) => {
          toast({ id: 'changeStatusToast', title: res?.message })
          callCarriersList()
        })
        .finally(closeDialog)
    } else if (type === 'offboard') {
      offboardCarrier(carrierId, eldVendor)
        .then((res: any) => {
          toast({ id: 'offboardCarrierToast', title: res?.message })
          callCarriersList()
        })
        .finally(closeDialog)
    } else {
      setBtnLoader(false)
      console.error(`Unsupported action type: ${type}`)
    }
  }

  const handleClose = () => {
    setShowTemplateForm(false)
    reset({})
  }

  const handleExport = () => {
    const csvString = apiRef.current.getDataAsCsv()
    const modifiedCsv = csvString
      .split('\n')
      .map((row) => row.split(',').slice(0, -1).join(','))
      .join('\n')
    const blob = new Blob([modifiedCsv], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = 'MyCarriersExport.csv'
    a.click()
    URL.revokeObjectURL(url)
  }

  const onSubmit = (data: any) => {
    setBtnLoader(true)
    const emptyValue = '-'
    if (isEditForm) {
      const payLoad : IEditCarrierPayload = {
        policyId: data?.policyId && data?.policyId !== '-' ? data?.policyId : null,
        carrierName: data?.carrierName && data?.carrierName !== '-' ? data?.carrierName : null,
        dot: data?.dot && data?.dot !== '-' ? data?.dot : null,
        cuid: data?.cuid && data?.cuid !== '-' ? data?.cuid : null,
      }
      var codeType = ""
      var codeValue = ""
      if (initialFormValues?.dot && initialFormValues?.dot !== emptyValue) {
        codeType = "DOT"
        codeValue = initialFormValues?.dot
      } else if (initialFormValues?.mc && initialFormValues?.mc !== emptyValue) {
        codeType = "MC"
        codeValue = initialFormValues?.mc
      } else if (initialFormValues?.scac && initialFormValues?.scac !== emptyValue) {
        codeType = "SCAC"
        codeValue = initialFormValues?.scac
      } else if (initialFormValues?.carrierId && initialFormValues?.carrierId !== emptyValue) {
        codeType = "TCID"
        codeValue = initialFormValues?.carrierId
      } else if (initialFormValues?.cuid && initialFormValues?.cuid !== emptyValue) {
        codeType = "CUID"
        codeValue = initialFormValues?.cuid
      }
      editCarrier(payLoad, codeType, codeValue)
        .then((res: any) => {
          toast({
            id: "editCarrierToast",
            subTitle: res?.description ?? 'Carrier was modified successfully',
          })
        })
        .finally(() => {
          setBtnLoader(false)
          setShowTemplateForm(false)
          callCarriersList()
        })
    }
  }

  const loadPagination = (pageNumber: number, pageSize: number) => {
    console.log(`pagenumber: ${pageNumber}, pageSize: ${pageSize}`)
  }

  const tableMenuItems = [
    {
      label: 'Download as CSV',
      key: 'export',
      action: () => {
        handleExport()
      },
    },
  ]

  const ReconnectCarrierDialogBody = () => {
    const [invitationUrl, setInvitationUrl] = useState<string>("")
    const [invitationLoading, setInvitationLoading] = useState<boolean>(false)

    const handleInviteCarrier = () => {
      setInvitationLoading(true)

      const codeTypeValue = getCodeTypesAndCodeValues(selectedRowData);
      if (!codeTypeValue) return

      const payload: any = {
        carrierInfo: {
          carrierName: selectedRowData?.carrierName,
        },
        contactInfo: {
          email: selectedRowData?.email,
          firstName: selectedRowData?.firstName,
          lastName: selectedRowData?.lastName,
        },
      }

      if (codeTypeValue?.codeType && codeTypeValue?.codeType !== 'DOT' && codeTypeValue?.codeType !== 'MC' && codeTypeValue?.codeType !== 'SCAC') {
        payload.carrierInfo.carrierCodes = [
          {
            codeType: codeTypeValue.codeType,
            codeValue: codeTypeValue.codeValue,
          },
        ]
      }

      const isCuid = codeTypeValue?.codeType && !['DOT', 'MC', 'SCAC'].includes(codeTypeValue?.codeType)
      const codeType = isCuid ? '' : codeTypeValue?.codeType
      const codeValue = isCuid ? '' : codeTypeValue?.codeValue

      inviteCarrier(payload, codeType, codeValue)
        .then((res: any) => {
          toast({ id: "generateInviteUrl", title: "Invitation also sent to your email" })
          const url = generateInvitationUrl(res?.token)
          setInvitationUrl(url)
        })
        .finally(() => { setInvitationLoading(false) })
    }

    return (
      <Grid display="flex" flexDirection="column" gap={2} alignItems="center">
        <Icon materialIcon='CloudSync' color='#44a3db' size={150} />
        <TypoGraph id='reconnectDialogBody' variant='body1' content={st.CONFIRMATION_RECONNECT_MESSAGE}></TypoGraph>
        <TextField
          value={invitationUrl}
          placeholder={st.RECONNECT_TEXTFIELD_PLACEHOLDER}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon materialIcon="Link" />
              </InputAdornment>
            ),
            endAdornment: (
              <Tooltip title={st.RECONNECT_COPY}>
                <InputAdornment position="end">
                  <Icon
                    materialIcon="ContentCopy"
                    iconButton={true}
                    onClick={() => { invitationUrl && copyToClipboard(invitationUrl)}}
                  />
                </InputAdornment>
              </Tooltip>
            ),
            readOnly: true,
          }}
          fullWidth
          variant="outlined"
        />
        <Button
          label={st.RECONNECT_GENERATE_INVITATION}
          variant="contained" onClick={handleInviteCarrier}
          loading={invitationLoading}
          disabled={!!invitationUrl || invitationLoading}
        />
      </Grid>
    )
  }

  const BladeContent = (_props: any) => {
    return (
      <Container className={styles.bladeBodyContainer}>
        <FormProvider {...methods}>
            <InputFormField
              id="carrierName"
              name="carrierName"
              label={strings.CARRIERS.CARRIER_NAME}
              maxLength={128}
              disabled={!isEditForm}
              required={!!initialFormValues.carrierName && initialFormValues.carrierName !== '-'}
            />
            <InputFormField
              id="firstName"
              name="firstName"
              label={strings.FIRST_NAME}
              maxLength={128}
              disabled={true}
            />
            <InputFormField
              id={"lastName"}
              name="lastName"
              label={strings.LAST_NAME}
              maxLength={128}
              disabled={true}
            />
            <InputFormField
              id="email"
              name="email"
              label={strings.EMAIL}
              maxLength={128}
              disabled={true}
              validate={emailValidator}
              required
            />
            <InputFormField
              id="dot"
              name="dot"
              label={strings.CARRIERS.DOT}
              maxLength={10}
              disabled={!isEditForm}
              required={!!initialFormValues.dot && initialFormValues.dot !== '-'}
            />
            <InputFormField
              id="mc"
              name="mc"
              label={strings.CARRIERS.MC}
              maxLength={20}
              disabled={true}
              required
            />
            <InputFormField
              id="scac"
              name="scac"
              label={strings.CARRIERS.SCAC}
              maxLength={10}
              disabled={true}
              required
            />
            <InputFormField
              id="cuid"
              name="cuid"
              label={strings.CARRIERS.CUID}
              maxLength={50}
              disabled={!isEditForm}
              required={companyHasCUID && !!initialFormValues.cuid && initialFormValues.cuid !== '-'}
            />
            {isEditForm &&
            <InputFormField
              id={"policyId"}
              name="policyId"
              label={strings.CARRIERS.POLICY_ID}
              maxLength={30}
              required={!!initialFormValues.policyId && initialFormValues.policyId !== '-'}
            />}
          </FormProvider>
      </Container>
    )
  }

  return (
    <Container>
      <DataGridTable
        apiRef={apiRef}
        id={"carriersTable"}
        title={'My Carriers'}
        loading={loading}
        data={myCarrierData || []}
        columns={myCarrierColumn}
        columnVisibilityModel={{ carrierId: false }}
        totalCount={myCarrierData?.length}
        {
          ...(isInsuranceCompany && {
            actionList: actionList,
            handleActions: handleActions
          })
        }
        reduceHeight={250}
        checkboxSelection={false}
        multiSearchFilter={false}
        menuList={tableMenuItems}
        getRowId={(row: any) => row?.carrierId + '|' + row?.eldVendorDisplayName}
        handlePagination={(pageNumber, pageSize) =>
          loadPagination(pageNumber, pageSize)
        }
      />
      {!isInsuranceCompany && 
      <Blade
        title={selectedRow?.carrierName}
        open={showCarrierDetails}
        onClose={() => setShowCarrierDetails(false)}
        bodyNode={<BladeContent />}
      />}
      {isInsuranceCompany &&
      <Blade
          open={showTemplateForm && isEditForm}
          title={isEditForm ? t.EDIT_CARRIER : ""}
          onClose={handleClose}
          bodyNode={<BladeContent />}
          footerBtns={{
            primary: {
              id: "createOrUpdateAgentBladeConfirmation",
              label: isEditForm ? strings.UPDATE : strings.SAVE,
              onClick: handleSubmit(onSubmit),
              loading: btnLoader,
              disabled: btnLoader,
            },
            secondary: {
              id: "closeAgentCreationOrUpdateBlade",
              label: 'Close',
              variant: 'outlined',
              onClick: handleClose,
            },
          }}
        />}
      <SubmitDialog
        cancelId="statusChangeDialog"
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={`${changeStatusText?.title} - ${selectedRowData?.carrierName}`}
        {...(changeStatusText?.type === st.TYPES.RECONNECT && {
          contentNode: <ReconnectCarrierDialogBody />
        })}
        {...(changeStatusText?.type !== st.TYPES.RECONNECT && {
          body1: changeStatusText?.body
        })}
        type={changeStatusText?.type === st.TYPES.OFFBOARD ? 'delete'
          : changeStatusText?.type === st.TYPES.RECONNECT ? 'none' : 'warning'}
        {...(changeStatusText?.type !== st.TYPES.RECONNECT && {
          primaryBtnProps: {
            id: "confirmStatusChange",
            label: changeStatusText?.confirmBtn,
            onClick: handleStatusChange,
            loading: btnLoader,
            disabled: btnLoader,
          },
        })}
        secondaryBtnProps={{
          label: st.CANCEL_BUTTON_TEXT,
          variant: 'outlined',
          color: changeStatusText?.type === st.TYPES.OFFBOARD ? 'error' : 'secondary'
        }}
      />
    </Container>
  )
}

export default MyCarrier
