import React, { useEffect, useRef, useState } from 'react';
import { Blade, Container, DataGridTable, } from 'components';
import { claimsVehiclesColumns } from './claimsVehiclesTableData';
import styles from './claimsVehiclesTable.module.scss'
import { SafetyDetails, VehicleEvents } from 'interfaces/IClaimsSearching';
import { formatEventTypeLabel } from 'tools/helper';
import { getSafetyEventDetail } from 'api/claimsSearching';
import ClaimsSafetyDetails from '../claimsSafetyDetails';

interface IClaimsVehiclesTableProps {
  carrierSelected: boolean;
  selectedCarrierId: any;
  selectedCarrierName: string;
  vehiclesSafetyEventsList: VehicleEvents | null;
  selectedEventId: number | null;
  onEventSelect: any;
}

const ClaimsVehiclesTable: React.FC<IClaimsVehiclesTableProps> = ({ carrierSelected, selectedCarrierId, selectedCarrierName, vehiclesSafetyEventsList, selectedEventId, onEventSelect }) => {
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [mappedVehicleSafetyEventsList, setMappedVehicleSafetyEventsList] = useState<any[]>([]);
  const [safetyDetails, setSafetyDetails] = useState<SafetyDetails | undefined>()
  const [hasVideo, setHasVideo] = useState<boolean>(false)
  const detailRef = useRef<any>(null)
  const downloadCooldownRef = useRef(0)

  useEffect(() => {
    if (carrierSelected && vehiclesSafetyEventsList) {
      setLoading(true)
      mapVehicleSafetyEventsList()
    } else {
      setMappedVehicleSafetyEventsList([])
    }
  }, [carrierSelected, vehiclesSafetyEventsList])

  useEffect(() => {    
    if (selectedEventId) {
      setLoading(true)
      callGetSafetyEventDetail()
    }
  }, [selectedEventId])

  const callGetSafetyEventDetail = () => {
    getSafetyEventDetail(selectedCarrierId, selectedEventId).then((res: any) => {
      setSafetyDetails(res)
      setHasVideo(!!res?.cameraData[0]?.videoUrl)
    }).finally(() => {
      setLoading(false)
    })
  }

  const mapVehicleSafetyEventsList = () => {
    const mappedList = vehiclesSafetyEventsList?.safetyEvents?.filter(
      (event) => event?.latitude && event?.longitude
    ).map(event => ({
      vehicleId: event?.id,
      assetId: event?.vehicleId.assetEldId,
      vin: event?.vin,
      eld: event?.eldProvider,
      driverId: event?.driverId.driverEldId,
      driverName: event?.driverName,
      event: formatEventTypeLabel(event?.type),
      eventTime: event?.time
    }))
    setMappedVehicleSafetyEventsList(mappedList ?? [])
    setLoading(false)
  }

  const handleRowClick = (row: any) => {
    setLoading(true);
    onEventSelect(row.id === selectedEventId ? null : row.id)
  }

  const handleDownloadClick = () => {
    const currentTime = Date.now()
    const cooldownTime = 3000

    if (!hasVideo || !detailRef.current) {
        return
    }

    if (currentTime - downloadCooldownRef.current < cooldownTime) {
        return
    }

    downloadCooldownRef.current = currentTime
    detailRef?.current?.handleDownload()
  }

  const handleRowClassName = (row: any) => {
    if (row?.id === selectedEventId) {
      return styles['highlighted-row']
    }
    return ''
  }

  return (
    <Container>
      <DataGridTable
        showTitle={false}
        loading={loading}
        data={mappedVehicleSafetyEventsList || []}
        columns={claimsVehiclesColumns?.columns}
        columnVisibilityModel={{ vehicleId: false }}
        checkboxSelection={false}
        totalCount={mappedVehicleSafetyEventsList?.length}
        reduceHeight={500}
        multiSearchFilter={false}
        getRowId={(row: any) => row?.vehicleId}
        rowClick={handleRowClick}
        rowClassName={handleRowClassName}
      />
      <Blade
        open={!!selectedEventId}
        title={'Details'}
        onClose={() => onEventSelect(null)}
        bodyNode={
          <ClaimsSafetyDetails
            selectedEvent={safetyDetails}
            selectedCarrierName={selectedCarrierName}
            loading={loading}
            ref={detailRef}
            />}
        size={'md'}
        footerBtns={{
          primary: {
            id: "downloadSafetyDetailVideo",
            label: 'Download Video',
            onClick: () => handleDownloadClick(),
            disabled: !hasVideo,
          },
          secondary: {
            id: "closeSafetyDetailVideo",
            label: 'Close',
            variant: 'outlined',
            onClick: () => onEventSelect(null)
          },
        }}
      />
    </Container>

  )
}

export default ClaimsVehiclesTable;
