import { Container, Icon, TypoGraph } from 'components'
import Button, { IButtonProps } from 'components/button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { ITypoGraphProps } from 'components/typograph'
import { ReactNode } from 'react'

interface ISubmitDialogProps {
  cancelId:string
  open: boolean
  primaryBtnProps?: IButtonProps
  secondaryBtnProps?: IButtonProps
  type?: 'success' | 'warning' | 'delete' | 'none'
  title?: string
  contentNode?: ReactNode
  body1Props?: ITypoGraphProps
  body1?: string
  body2?: string
  showCancel?: boolean
  handleClose: () => void
}

const SubmitDialog: React.FC<ISubmitDialogProps> = (props) => {
  const {
    cancelId,
    open,
    handleClose,
    primaryBtnProps,
    secondaryBtnProps,
    body1Props,
    contentNode,
    type = 'success',
    title = 'Confirmation',
    showCancel = true,
    body1,
    body2,
  } = props
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {type !== 'none' && <Icon name={`icon_dialog_${type}`} height={150} width={300} />}
        {contentNode ? (
          <Container>{contentNode}</Container>
        ) : (
          <>
            <TypoGraph
              mt={2}
              mb={2}
              content={body1}
              variant="body1"
              align="center"
              {...body1Props}
            />
            <TypoGraph content={body2} variant="body2" align="center" />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Container
          sx={{
            gridTemplateColumns: primaryBtnProps ? 'auto auto' : 'auto',
            justifyContent: 'end',
            gridGap: '1rem',
            padding: '12px 20px',
          }}
        >
          {showCancel && (
            <Button
                id={cancelId}
              label="Cancel"
              variant="text"
              onClick={handleClose}
              color="inherit"
              {...secondaryBtnProps}
            />
          )}
          {primaryBtnProps && (
            <Button
              color={type === 'delete' ? 'error' : 'primary'}
              {...primaryBtnProps}
            />
          )}
        </Container>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitDialog
