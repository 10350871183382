const rbacDomains = {
    RISK_DASHBOARD: "RISK",
    CARRIERS: "CARRIERS",
    ACCOUNTS: "ACCOUNTS",
    REPORTING: "REPORTING",
    SETTINGS: "SETTINGS",
    PROFILE: "PROFILE",
    FLEET_REPORTING: "FLEET_REPORTING",
    CLAIMS_SEARCHING: 'CLAIMS_SEARCHING'
}

export default rbacDomains