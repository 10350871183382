import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid";
import styles from './claimsVehiclesTable.module.scss'
import { stringDateComparator } from "tools/comparators";
import { TypoGraph } from "components";
import { formatDate } from "tools/helper";

const claimsVehiclesColumnsDefinition = [
    {
        field: 'vehicleId',
        headerName: 'Vehicle Id',
        minWidth: 50,
        flex: 0,
        sortable: false,
        className: styles['pointer'],
    },
    {
        field: 'assetId',
        headerName: 'Asset ID',
        minWidth: 120,
        flex: 1,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'vin',
        headerName: 'VIN',
        minWidth: 110,
        flex: 1,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'eld',
        headerName: 'ELD',
        minWidth: 100,
        flex: 0.9,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'driverId',
        headerName: 'Driver ID',
        minWidth: 100,
        flex: 0.9,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'driverName',
        headerName: 'Driver Name',
        minWidth: 110,
        flex: 0.9,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'event',
        headerName: 'Event',
        minWidth: 100,
        flex: 0.9,
        sortable: true,
        className: styles['pointer'],
    },
    {
        field: 'eventTime',
        headerName: 'Event Date',
        minWidth: 100,
        flex: 0.9,
        sortComparator: stringDateComparator,
        renderCell: (params: GridRenderCellParams) => (
            <TypoGraph component="span" variant="body2">
                {formatDate(params?.row?.eventTime) ?? params?.row?.eventTime ?? '-'}
            </TypoGraph>
        ),
        sortable: true,
        className: styles['pointer'],
    },
]

const claimsVehiclesColumns = {
    columns: claimsVehiclesColumnsDefinition.map(({ field, headerName, minWidth, flex, sortable, className, renderCell }) => ({
        field,
        headerName,
        minWidth,
        flex,
        sortable,
        cellClassName: (params: GridCellParams<any>) => className,
        renderCell
    }))
};


export {
    claimsVehiclesColumns,
}