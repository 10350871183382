import React, { useMemo, useRef, useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { Chip, TypoGraph } from 'components';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import styles from './carrierSelect.module.scss'
import strings from 'strings';
import { ClaimsCarrier } from 'interfaces/IClaimsSearching';

interface CarrierSelectProps {
    carrierList: ClaimsCarrier[];
    onCarrierSelect: (carrier: any) => void;
}

const t = strings.CLAIMS_SEARCHING;

const CarrierSelect: React.FC<CarrierSelectProps> = ({ carrierList, onCarrierSelect }) => {
    const [selectedCarrier, setSelectedCarrier] = useState<any>(null)
    const [selectedCarrierInput, setSelectedCarrierInput] = useState<any>('')
    const [carrierNameDisplay, setCarrierNameDisplay] = useState<string>(t.CARRIER_FOR_CLAIMS)
    const [displayChip, setDisplayChip] = useState<boolean>(false)

    const options = useMemo(() => {
        return carrierList.map((carrier) => {
            const firstLetter = carrier?.carrierName[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                carrier,
            };
        });
    }, [carrierList])

    const handleCarrierSelection = (newValue: any) => {
        if (newValue) {
            const carrier = newValue?.carrier
            const carrierName = newValue?.carrier?.carrierName
            setCarrierNameDisplay(carrierName)
            onCarrierSelect(carrier)
            setDisplayChip(true)
            setSelectedCarrier(null)
            setSelectedCarrierInput('')
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={7} display="flex" alignItems="center" gap={2}>
                <TypoGraph
                    variant="h1"
                    mb={0}
                >
                    {carrierNameDisplay}
                </TypoGraph>
                {displayChip && <Chip
                    label={`Updated ${moment().format('dddd, MMMM Do')}`}
                    variant="filled"
                    sx={{
                        backgroundColor: '#E1FAEB',
                        color: '#20693D',
                    }}
                />}
            </Grid>
            <Grid
                item xs={12} sm={12} md={5} display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gap={2}>
                <Autocomplete
                    options={options.sort((a, b) => -b?.firstLetter.localeCompare(a?.firstLetter))}
                    groupBy={(option) => option?.firstLetter}
                    getOptionLabel={(option) => option?.carrier?.carrierName}
                    renderInput={(params) => (
                        <TextField {...params} label={t.CARRIERS_SEARCH} variant="outlined" />
                    )}
                    sx={{ width: '100%' }}
                    value={selectedCarrier}
                    blurOnSelect
                    onChange={(event: any, newValue: string | null) => {
                        if (newValue) {
                            handleCarrierSelection(newValue)
                        }
                    }}
                    inputValue={selectedCarrierInput}
                    onInputChange={(event, newInputValue) => {
                        setSelectedCarrierInput(newInputValue)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default CarrierSelect;
