import React, { ReactNode } from 'react';
import './loadingWrapper.scss';
import Spinner from 'components/spinner';

interface LoadingWrapperProps {
  isLoading: boolean;
  children: ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ isLoading, children }) => {
  return (
    <div className={`loading-wrapper ${isLoading ? 'loading' : ''}`}>
      {isLoading && <div className="spinner-overlay"><Spinner /></div>}
      <div className={`content ${isLoading ? 'disabled' : ''}`}>
        {children}
      </div>
    </div>
  );
};

export default LoadingWrapper;