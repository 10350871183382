import { IMenuAccess, IMenuDefinition } from "interfaces";
import {
    carriersAccessControl,
    claimsSearchingAccessControl,
    profileAccessControl,
    reportingAccessControl,
    riskDashboardAccessControl,
    settingsAccessControl
} from "rbac";
import strings from "strings";

// Preparing our menu items with their access control checks
const menus: IMenuDefinition[] = [
    {
        name: strings.MENU.DASHBOARD,
        url: strings.ROUTES.DASHBOARD,
        canViewMenu: riskDashboardAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.CARRIERS,
        url: strings.ROUTES.CARRIERS,
        canViewMenu: carriersAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.REPORTING,
        url: strings.ROUTES.REPORTING,
        canViewMenu: reportingAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.CLAIMS_SEARCHING,
        url: strings.ROUTES.CLAIMS_SEARCHING,
        canViewMenu: claimsSearchingAccessControl().canViewMenu,
    },
    // Note: Help is not here since it doesn't need a permission check
    {
        name: strings.MENU.SETTINGS,
        url: strings.ROUTES.SETTINGS,
        canViewMenu: settingsAccessControl().canViewMenu,
    },
    {
        name: strings.MENU.PROFILE,
        url: strings.ROUTES.PROFILE,
        canViewMenu: profileAccessControl().canViewMenu,
    }
];

const getInsuranceMenuAccess = (): IMenuAccess[] => {
    const menuList: IMenuAccess[] = menus
        .filter(menu => menu.canViewMenu()) // Filter based on access control
        .map(({ name, url }) => ({ name, url })); // Map to the desired output structure

    // Adding the Help menu since its inclusion doesn't depend on permissions
    menuList.push({ name: strings.MENU.HELP });

    return menuList;
}

export default getInsuranceMenuAccess;