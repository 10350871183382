import colors from 'theme/colors'
const earnestMoneyStatus = [
  {
    label: 'All',
  },
  {
    label: 'New',
  },
  {
    label: 'Held',
    badgeColor: colors.warning,
  },
  {
    label: 'Approved',
    badgeColor: colors.success,
  },
  {
    label: 'Completed',
  },
  {
    label: 'Rejected',
    badgeColor: colors.error,
  },
]

const carriersTabs = [
  {
    label: 'My Carriers',
  },
  {
    label: 'Invited Carriers',
  },
]

const carriersForAgentsTabs = [
  {
    label: 'My Carriers',
  },
]

const connectedAccountsTabs = [
  {
    label: 'My Accounts',
  },
  {
    label: 'Invited Accounts',
  },
]

const userManagementStatus = [
  {
    label: 'All',
  },
  {
    label: 'Active',
  },
  {
    label: 'Inactive',
  },
]

const agentManagementStatus = [
  {
    label: 'All',
  }
]

const tagManagementStatus = [
  {
    label: 'All',
  }
]

const paymentsDetailTabs = [
  {
    label: 'Details',
  },
  {
    label: 'Notes',
  },
  {
    label: 'History',
  },
]

const dashboardTabs = [
  {
    label: 'Summary',
  },
  {
    label: 'Safety',
  },
  {
    label: 'Speed',
  },
  {
    label: 'Geographic',
  },
]

const reportingTabs = [
  {
    label: 'Portfolio Mileage Reporting',
  },
  {
    label: 'Portfolio Location Reporting',
  },
  {
    label: 'Portfolio Heartbeat'
  },
  {
    label: 'Portfolio Fleets Miles by Zip'
  },
]

const carrierDetailsTabs = [
  {
    label: 'Details',
  },
  {
    label: 'Carrier Heartbeat',
  },
  {
    label: 'Carrier Heartbeat Trends'
  },
  {
    label: 'Carrier Mileage Reporting'
  },
  {
    label: 'Carrier Location Reporting',
  },
  {
    label: 'Carrier Miles by Zip'
  },
  {
    label: 'Radius of Operation'
  }
]

const fleetDetailsTabsForAgents = [
  {
    label: 'Details',
  },
  {
    label: 'Fleet Heartbeat',
  },
  {
    label: 'Fleet Percentiles'
  },
  {
    label: 'Fleet Miles By Zip'
  }
]

const fleetReportingTabs = [
  {
    label: 'Fleet Heartbeat',
  },
  {
    label: 'Fleet Percentiles'
  },
  {
    label: 'Fleet Details'
  },
  {
    label: 'Fleet Miles By Zip'
  }
]

const claimsMapLegendTabs = [
  {
    label: 'Safety Events',
  },
  {
    label: 'Routes',
  },
]

const carrierInviteStatus: any = {
  INVITED: {
    background: '#E1FAEB',
    color: '#20693D',
    label: 'Invited',
  },
  EXPIRED: {
    background: '#F5F7FA',
    color: '#707A8B',
    label: 'Expired',
  },
  VERIFICATION_FAILED: {
    background: '#FAF0F0',
    color: '#BA0D0D',
    label: 'Verification Failed',
  },
  REINVITED: {
    background: '#F0F5FC',
    color: '#1D5BBF',
    label: 'ReInvited',
  },
  IN_NETWORK: {
    background: '#E1FAEB',
    color: '#20693D',
    label: 'In Network',
  },
  NOT_IN_NETWORK: {
    background: '#F5F7FA',
    color: '#707A8B',
    label: 'Not In Network',
  },
}

const carrierStatus: any = {
  ACTIVE: {
    background: '#E1FAEB',
    color: '#20693D',
    label: 'Active',
  },
  DISCONNECTED: {
    background: '#FFF3E0',
    color: '#FB8C00',
    label: 'Disconnected',
  },
  PAUSED: {
    background: '#E3EEFB',
    color: '#1D5BBF',
    label: 'Paused',
  },
}

export {
  earnestMoneyStatus,
  paymentsDetailTabs,
  userManagementStatus,
  carriersTabs,
  fleetDetailsTabsForAgents,
  carriersForAgentsTabs,
  connectedAccountsTabs,
  carrierInviteStatus,
  dashboardTabs,
  carrierDetailsTabs,
  reportingTabs,
  fleetReportingTabs,
  agentManagementStatus,
  tagManagementStatus,
  claimsMapLegendTabs,
  carrierStatus
}