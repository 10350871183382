import { isNumber } from "lodash"
import { isAgentLogin } from "./auth"
import { Toast as toast} from "components"

const getCarrierParamsData = (formData: any) => {
  const paramData: any = {}

  for (const key in formData) {
    if (formData[key] !== undefined && key !== 'carrierName:fuzzy') {
      paramData['codeType'] = key?.toUpperCase()
      paramData['codeValue'] = formData[key]
    } else if (formData[key] !== undefined && key === 'carrierName:fuzzy') {
      paramData[key] = formData[key]
    }
  }

  return paramData
}

const getChildCarrierParamsData = (formData: any) => {
  const paramData: any = {}

  for (const key in formData) {
    if (formData[key] !== undefined ) {
      paramData[key] = formData[key]
    }
  }

  return paramData
}

const getCodeTypeValue = (listData: any) => {
  const paramData: any = {}
  listData.forEach((item: any) => {
    if (item.codeType === 'DOT') {
      paramData['codeType'] = item.codeType
      paramData['codeValue'] = item.codeValue
    } else if (item.codeType === 'UUID') {
      paramData['codeType'] = item.codeType
      paramData['codeValue'] = item.codeValue
    }
  })
  return paramData
}

// Function to check if all keys are present and have non-empty values
function areKeysValid(obj: any, keys: any[]) {
  for (const key of keys) {
    if (!(key in obj) || !obj[key] || obj[key].includes('�')) {
      return false
    }
  }
  return true
}

const getPercentageDiff = (currentValue: number, previousValue: number, totalValue: number): number => {
  if (totalValue === 0) return 0

  const previousPercentage = (previousValue / totalValue) * 100
  const currentPercentage = (currentValue / totalValue) * 100
  const diff = currentPercentage - previousPercentage

  return diff
}

const generateVariationTooltip = (currentValue: any, previousValue: any, totalValue: number, withPercentages: boolean): string => {
  if ((currentValue === undefined && previousValue === undefined)
    || (currentValue === null && previousValue === null) 
    || (isNaN(currentValue) && isNaN(previousValue))) {
    return 'No data'
  }
  if (currentValue === undefined || currentValue === null || isNaN(currentValue)) {
    return 'No data'
  }
  if (previousValue === undefined || previousValue === null || isNaN(previousValue)) {
    if (withPercentages === true && !isNaN(currentValue))
      return `${currentValue.toFixed(2)} (100%)`
    else
      return `${currentValue.toFixed(2)}`
  }

  if (withPercentages === true && !isNaN(totalValue) && !isNaN(previousValue) && !isNaN(currentValue)) {
    const previousPercentage = (previousValue / totalValue) * 100
    const currentPercentage = (currentValue / totalValue) * 100

    if (!isNaN(previousPercentage) && !isNaN(currentPercentage)) {
      return `${previousValue.toFixed(2)} (${previousPercentage.toFixed(2)}%) -> ${currentValue.toFixed(2)} (${currentPercentage.toFixed(2)}%)`
    } else {
      return `${previousValue.toFixed(2)} -> ${currentValue.toFixed(2)}`
    }
  }
  else {
    return `${previousValue.toFixed(2)} -> ${currentValue.toFixed(2)}`
  }
}

const formatRiskCell = (value: any, decimals?: number) => {
  const formatValue = (num: number, decimals?: number) => {
    if (isNaN(num)) return '-';
    const formatted = decimals ? Math.abs(num).toFixed(decimals) : Math.trunc(Math.abs(num));
    return formatted?.toLocaleString() || '-'
  }
  const isEmptyString = (str: any): boolean => typeof str === 'string' && str.trim() === ''

  if (isAgentLogin()) {
    if (isNumber(value) || isNumber(parseFloat(value))) {
      return formatValue(value, decimals)
    }
    if (value === null || value === undefined || isEmptyString(value)) {
      return '-'
    }
    return String(value)
  }
  else {
    return formatValue(value, decimals)
  }
}

const ratingColor = (value: any) => {
  if (typeof value !== 'string') {
    return '';
  }
  switch (value?.toLocaleLowerCase()) {
    case 'poor':
      return '#EA1C04';
    case 'average':
      return '#4276C9';
    case 'great':
      return '#34864E';
    default:
      return '';
  }
};

const copyToClipboard = (value: string, message?: string) => {
  navigator.clipboard.writeText(value);
  toast({
    id: value,
    type: "info",
    title: `Copied to clipboard`,
    subTitle: message ? message : value,
    autoClose: 2000
  })
}

const isArrayEmpty = (arr: any) : boolean => {
  return !arr || arr.length === 0
}

const formatEventTypeLabel = (item: any) => {
  return item
    ?.toLowerCase()
    ?.split('_')
    ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}

const getEventIcon = (eventType: string, useTwoTone?: boolean): string => {
  if (useTwoTone) {
    const eventIconMapTwoTone = new Map<string, string>([
      ['HARSH_BRAKING', 'SpeedTwoTone'],
      ['TAILGATING', 'WarningAmberTwoTone'],
      ['HARSH_CORNER', 'TurnLeftTwoTone'],
      ['HARSH_ACCELERATION', 'ArrowUpwardTwoTone'],
      ['LANE_DEPATURE', 'DirectionsCarTwoTone'],
      ['ROUGH_UNEVEN_SURFACE', 'RemoveRoadTwoTone'],
      ['OTHER', 'ReportProblemTwoTone'],
      ['ROLL_STABILITY', 'SyncProblemTwoTone'],
      ['SIGN_VIOLATION_OR_ROLLING', 'TrafficTwoTone'],
      ['PARKED_ON_HIGHWAY_RAMP', 'WrongLocationTwoTone'],
      ['CRITICAL_TAILGATING', 'WarningAmberTwoTone'],
      ['NEAR_COLLISION', 'DirectionsCarTwoTone'],
      ['CRASH', 'CarCrashTwoTone'],
      ['POSSIBLE_ACCIDENT', 'CarCrashTwoTone'],
    ])
    return eventIconMapTwoTone.get(eventType) ?? 'ReportProblemTwoTone'
  }

  const eventIconMap = new Map<string, string>([
    ['HARSH_BRAKING', 'Speed'],
    ['TAILGATING', 'WarningAmber'],
    ['HARSH_CORNER', 'TurnLeft'],
    ['HARSH_ACCELERATION', 'ArrowUpward'],
    ['LANE_DEPATURE', 'DirectionsCar'],
    ['ROUGH_UNEVEN_SURFACE', 'RemoveRoad'],
    ['OTHER', 'ReportProblem'],
    ['ROLL_STABILITY', 'SyncProblem'],
    ['SIGN_VIOLATION_OR_ROLLING', 'Traffic'],
    ['PARKED_ON_HIGHWAY_RAMP', 'WrongLocation'],
    ['CRITICAL_TAILGATING', 'WarningAmber'],
    ['NEAR_COLLISION', 'DirectionsCar'],
    ['CRASH', 'CarCrash'],
    ['POSSIBLE_ACCIDENT', 'CarCrash'],
  ])
  return eventIconMap.get(eventType) ?? 'ReportProblem'
}

const formatDate = (dateString: string) => {
  if (!dateString) return null
  return new Date(dateString).toLocaleString()
}

const getCodeTypesAndCodeValues = (selectedCarrierCodes: any) => {
  if (!selectedCarrierCodes) return null

  const priorityMappings = [
    { key: 'dot', codeType: 'DOT' },
    { key: 'mc', codeType: 'MC' },
    { key: 'scac', codeType: 'SCAC' },
    { 
      key: 'cuid', 
      codeType: selectedCarrierCodes.cuidName !== '-' ? selectedCarrierCodes.cuidName : null 
    },
  ]

  for (const { key, codeType } of priorityMappings) {
    if (codeType && selectedCarrierCodes[key]) {
      return { codeType, codeValue: selectedCarrierCodes[key] }
    }
  }

  return null
}

const generateInvitationUrl = (token: string) => {
  const currentUrl = window.location.href
  const isLocalhost = currentUrl.includes('localhost')
  const baseUrl = isLocalhost
    ? 'http://localhost:3000'
    : `https://${window.location.hostname}`

  return `${baseUrl}/cob?invitationkey=${token}`
}

export { 
  getCarrierParamsData, 
  getChildCarrierParamsData, 
  getCodeTypeValue, 
  areKeysValid, 
  getPercentageDiff, 
  generateVariationTooltip,
  formatRiskCell,
  ratingColor,
  copyToClipboard,
  isArrayEmpty,
  formatEventTypeLabel,
  getEventIcon,
  formatDate,
  getCodeTypesAndCodeValues,
  generateInvitationUrl
}
