import { Card, Divider, Collapse, Box, Grid } from '@mui/material'
import { MapControl, ControlPosition } from '@vis.gl/react-google-maps'
import { Icon, Tabs, TypoGraph } from 'components'
import { claimsMapLegendTabs } from 'constants/status'
import legendItems  from './legendItems'
import React, { useState } from 'react'
import colors from 'theme/colors'

interface ClaimsMapsLegendProps {
  position: ControlPosition
}

const ClaimsMapsLegend: React.FC<ClaimsMapsLegendProps> = ({ position }) => {
  const [showLegend, setShowLegend] = useState<boolean>(false)
  const [tabValue, setTabValue] = useState(0)

  const toggleLegend = () => {
    setShowLegend(!showLegend)
  }

  return (
    <MapControl position={position}>
      <Card
        square
        sx={{
          overflow: 'hidden',
          transition: 'width 0.3s ease, opacity 0.3s ease',
          width: '450px',
          opacity: 0.75,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: '8px 16px', cursor: 'pointer' }}
          onClick={toggleLegend}
        >
          <TypoGraph
            content="Map Legend"
            variant="subtitle2"
            color={colors.primary}
          />
          <Icon
            materialIcon={!showLegend ? 'Add' : 'Remove'}
            color={colors.primary}
          />
        </Box>

        <Collapse in={showLegend}>
          <Divider />

          <Tabs
            tabData={claimsMapLegendTabs}
            id={claimsMapLegendTabs[tabValue].label}
            value={tabValue}
            handleChange={(_e, val) => setTabValue(val)}
            fullWidthTabs={true}
            sxTabs={{ minHeight: '35px' }}
            sxTab={{ minHeight: '35px', height: '35px' }}
          />

          <Box
            sx={{
              maxHeight: '150px',
              overflowY: 'auto',
              padding: '16px',
              position: 'relative',
              transition: 'max-height 0.3s ease-in-out',
            }}
          >
            <Grid
              container
              spacing={1}
              sx={{
                position: 'relative',
                top: '0',
                transition: 'max-height 0.3s ease-in-out',
              }}
            >
              {tabValue === 0 ? (
                <Grid container spacing={1}>
                  {legendItems[tabValue].map((item: any, index: any) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box display="flex" alignItems="center">
                        {item.icon}
                        <TypoGraph variant="body2" sx={{ marginLeft: '12px' }}>
                          {item.label}
                        </TypoGraph>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <Grid container item xs={6} spacing={1}>
                    {legendItems[tabValue]
                      .slice(0, 3)
                      .map((item: any, index: any) => (
                        <Grid item xs={12} key={index}>
                          <Box display="flex" alignItems="center">
                            {item.icon}
                            <TypoGraph
                              variant="body2"
                              sx={{ marginLeft: '12px' }}
                            >
                              {item.label}
                            </TypoGraph>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    {legendItems[tabValue]
                      .slice(3)
                      .map((item: any, index: any) => (
                        <Grid item xs={12} key={index}>
                          <Box display="flex" alignItems="center">
                            {item.icon}
                            <TypoGraph
                              variant="body2"
                              sx={{ marginLeft: '12px' }}
                            >
                              {item.label}
                            </TypoGraph>
                          </Box>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Collapse>
      </Card>
    </MapControl>
  )
}

export default ClaimsMapsLegend
